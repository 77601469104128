import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Popup} from '@partssourceinc/react-ui-core';
import $ from 'jquery';
import 'less/cybersource.less';
export default class SettingsCybersourceDialog extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('#CreditCardForm').submit();
    }

    render() {
        const {cybersourceRequest, showError, isEdit} = this.props;

        const formUrl = isEdit ? process.env.REACT_APP_CYBERSOURCE_URL + '/token/update' :
            process.env.REACT_APP_CYBERSOURCE_URL + '/token/create';

        return (
            <Popup
                show={true}
                onCancel={this.props.onClose}
                hideButtons={true}>
                {showError ? <div>There was a problem submitting payment information.</div> :
                    <div className="row">
                        <form action={formUrl} id="CreditCardForm" target="CardDetails" method="post">
                            {isEdit ? <span>
                                <input type="hidden" name="transaction_type" value={cybersourceRequest.transaction_type} />
                                <input type="hidden" name="allow_payment_token_update" value={cybersourceRequest.allow_payment_token_update} />
                                <input type="hidden" name="access_key" value={cybersourceRequest.access_key} />
                                <input type="hidden" name="profile_id" value={cybersourceRequest.profile_id} />
                                <input type="hidden" name="reference_number" value={cybersourceRequest.reference_number} />
                                <input type="hidden" name="payment_token" value={cybersourceRequest.payment_token} />
                                <input type="hidden" name="amount" value={cybersourceRequest.amount} />
                                <input type="hidden" name="currency" value={cybersourceRequest.currency} />
                                <input type="hidden" name="locale" value={cybersourceRequest.locale} />
                                <input type="hidden" name="override_custom_cancel_page" value={cybersourceRequest.override_custom_cancel_page} />
                                <input type="hidden" name="override_custom_receipt_page" value={cybersourceRequest.override_custom_receipt_page} />
                                <input type="hidden" name="merchant_defined_data6" value={cybersourceRequest.merchant_defined_data6} />
                                <input type="hidden" name="transaction_uuid" value={cybersourceRequest.transaction_uuid} />
                                <input type="hidden" name="signed_date_time" value={cybersourceRequest.signed_date_time} />
                                <input type="hidden" name="signed_field_names" value={cybersourceRequest.signed_field_names} />
                                <input type="hidden" name="unsigned_field_names" value={cybersourceRequest.unsigned_field_names} />
                                <input type="hidden" name="signature" value={cybersourceRequest.signature} />
                            </span>
                                :
                                <span>
                                    <input type="hidden" name="access_key" value={cybersourceRequest.access_key} />
                                    <input type="hidden" name="profile_id" value={cybersourceRequest.profile_id} />
                                    <input type="hidden" name="transaction_uuid" value={cybersourceRequest.transaction_uuid} />
                                    <input type="hidden" name="signed_field_names" value={cybersourceRequest.signed_field_names} />
                                    <input type="hidden" name="unsigned_field_names" value={cybersourceRequest.unsigned_field_names} />
                                    <input type="hidden" name="signed_date_time" value={cybersourceRequest.signed_date_time} />
                                    <input type="hidden" name="locale" value={cybersourceRequest.locale} />
                                    <input type="hidden" name="bill_to_address_country" value={cybersourceRequest.bill_to_address_country} />
                                    <input type="hidden" name="bill_to_email" value={cybersourceRequest.bill_to_email} />
                                    <input type="hidden" name="card_expiry_date" value={cybersourceRequest.card_expiry_date} />
                                    <input type="hidden" name="override_custom_cancel_page" value={cybersourceRequest.override_custom_cancel_page} />
                                    <input type="hidden" name="override_custom_receipt_page" value={cybersourceRequest.override_custom_receipt_page} />
                                    <input type="hidden" name="merchant_defined_data6" value={cybersourceRequest.merchant_defined_data6} />
                                    <input type="hidden" name="transaction_type" value={cybersourceRequest.transaction_type} />
                                    <input type="hidden" name="reference_number" value={cybersourceRequest.reference_number} />
                                    <input type="hidden" name="amount" value={cybersourceRequest.amount} />
                                    <input type="hidden" name="currency" value={cybersourceRequest.currency} />
                                    <input type="hidden" name="signature" value={cybersourceRequest.signature} />
                                </span>}
                        </form>
                    </div>}
                {!showError && <iframe name="CardDetails" id="CardDetails" className="col-xs-12" />}
            </Popup>
        );
    }
}
