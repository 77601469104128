import axios from 'axios';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {NavLink} from 'react-router-dom';
import _ from 'lodash';
import {TextField, Checkbox, Popup, Dropdown} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import BreadCrumbs from 'components/BreadCrumbs';
import {logEvent} from 'utility';
import SettingsCybersourceDialog from 'components/Settings/SettingsCybersourceDialog';
import * as SettingsStore from 'stores/Settings';
import * as UserStore from 'stores/User';

import 'less/settings/settingslanding.less';
import { throws } from 'assert';

@withRouter
@connect((state) => ({user: state.user}), SettingsStore.actionCreators)
export default class MyAccountPaymentMethod extends Component {
    static propTypes = {
        user: UserStore.StateShape,
        ...SettingsStore.ActionShape,
    };

    constructor(props) {
        super(props)

        this.state = {
            userId: props.user.info.contactId,
            ccNotification: null,
            isSaving: false,
            showCCDialog: false,
            isCCEdit: false,
            cybersourceRequest: {},
            showCybersourceError: false,
            creditCards: [],
            deletedCards: [],
            selectedCreditCard: {},
            poNotification: null,
            poNumbers: [],
            deletedPoNumbers: [],
            showPoDialog: false,
            selectedPoLine: {},
            showErrors: false,
            savingPo: false,
            token: '',
            widgetId: '',
        };
    }

    componentDidMount() {
        const {user: {settings: {siteLvlCC, canUseCC, poRequiredCC}, info: {rootCompanyId}, turnstileCompanies}, history} = this.props;
        if (siteLvlCC && !canUseCC && !poRequiredCC) history.push('/settings');
        this.getCards();
        this.getBlanketPos();
        window.addEventListener('message', this.handleCyberSourceResponse);

        if (window.turnstile && turnstileCompanies?.includes(rootCompanyId)) {
            const widgetId = window.turnstile.render('#turnstile', {
                sitekey: process.env.REACT_APP_TURNSTILE_KEY,
                callback: (token) => {
                    this.setState({token});
                },
            });
            this.setState({widgetId});
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleCyberSourceResponse);
    }

    handleCyberSourceResponse = (response) => {
        let currentLocation = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        if (currentLocation !== response.origin || (!response.data.transactionId && !response.data.showMessage)) {
            return;
        }

        if (!response.data.transactionId && response.data.showMessage) {
            this.setState({showCybersourceError: true});
            return;
        }

        const {selectedCreditCard, userId} = this.state;

        let vm = {
            CreditCard: selectedCreditCard,
            CybersourceResponse: response.data,
        };

        axios.post('/ShoppingService/api/v1/account/card/save', vm).then(x => {
            logEvent('Payment Methods Edit', {UserId: userId});
            this.setState({showCCDialog: false});
            this.getCards();
        });
    }

    getCards() {
        axios.get('/ShoppingService/api/v1/account/card').then(x => {
            this.setState({creditCards: x.data});
        });
    }

    onCheckAllChange(e) {
        let {creditCards} = this.state;
        creditCards.forEach(x => x.checked = e.checked);
        this.setState({creditCards, ccNotification: null});
    }

    onCreditCardDelete = () => {
        const {creditCards, userId} = this.state;
        let cardsToDelete = creditCards.filter(x => x.checked);
        const ccNotification = `(${cardsToDelete.length}) ${cardsToDelete.length === 1 ? 'item' : 'items'} deleted`;
        this.setState({deletedCards: JSON.parse(JSON.stringify(cardsToDelete)), ccNotification});
        axios.post('/ShoppingService/api/v1/account/card/delete', cardsToDelete).then(() => {
            logEvent('Payment Methods Edit', {UserId: userId});
            this.getCards();
        });
    }

    onReActivateDeletedCards = () => {
        const {deletedCards, userId} = this.state;
        axios.post('/ShoppingService/api/v1/account/card/activate', deletedCards).then(() => {
            logEvent('Payment Methods Edit', {UserId: userId});
            this.getCards();
            this.setState({ccNotification: null, deletedCards: []});
        });
    }

    generateCybersourceRequest(card) {
        const { user: {info: {rootCompanyId}, turnstileCompanies} } = this.props;
        const { token, widgetId } = this.state;

        if (card === undefined) {
            card = null;
            this.setState({isCCEdit: false, selectedCreditCard: card});
        } else {
            this.setState({isCCEdit: true, selectedCreditCard: card});
        }

        axios.post('/SettingsService/api/v1/generateSaveCreditCardRequest', { card, token })
            .then(x => {
                this.setState({cybersourceRequest: x.data, showCCDialog: true});
            })
            .finally(_ => {
                if (window.turnstile && turnstileCompanies?.includes(rootCompanyId)) {     
                    window.turnstile.reset(widgetId);
                }
            })
    }

    getCardLogo(cardTypeCode) {
        switch (cardTypeCode) {
            case 'VISA':
                return '/images/visa.png';
            case 'AMX':
                return '/images/amex.png';
            case 'MC':
                return '/images/mastercard.png';
            default:
                return '/images/visa.png';
        }
    }

    renderCreditCardRow(card) {
        const {token} = this.state;

        let isSelected = card.checked || false;
        return (
            <tr key={card.paymentToken}>
                <td><div className="checkbox-wrapper"><Checkbox checked={isSelected} onChange={() => this.onCreditCardCheckChange(card)} /></div></td>
                <td disabled={!!token} onClick={() => this.generateCybersourceRequest(card)}>
                    <span className="card-type"><img src={this.getCardLogo(card.cardTypeCode)} />{card.cardType}</span>
                    {` ending in ${card.creditCardDisplay.substring(card.creditCardDisplay.length - 4)}`}
                </td>
                <td disabled={!!token} onClick={() => this.generateCybersourceRequest(card)}>
                    {card.cardExpiryDate}
                </td>
            </tr>
        );
    }

    onCreditCardCheckChange(card) {
        let {creditCards} = this.state;
        let currentCard = creditCards.find(x => x.paymentToken === card.paymentToken);
        currentCard.checked = !card.checked;
        this.setState({creditCards, ccNotification: null});
    }

    getBlanketPos() {
        axios.get('/ShoppingService/api/v1/account/blanketPo').then(x => {
            this.setState({poNumbers: x.data});
        });
    }

    renderPoRow(po) {
        let isSelected = po.checked || false;
        return (
            <tr key={po.id}>
                <td><div className="checkbox-wrapper"><Checkbox checked={isSelected} onChange={() => this.onPoCheckChange(po)} /></div></td>
                <td onClick={() => this.setState({showPoDialog: true, selectedPoLine: po})}>{po.facilityName}</td>
                <td onClick={() => this.setState({showPoDialog: true, selectedPoLine: po})}>{po.poNumber}</td>
            </tr>
        );
    }

    onCheckAllPoChange(e) {
        let {poNumbers} = this.state;
        poNumbers.forEach(x => x.checked = e.checked);
        this.setState({poNumbers, poNotification: null});
    }

    onPoCheckChange(po) {
        let {poNumbers} = this.state;
        let currentPo = poNumbers.find(x => x.id === po.id);
        currentPo.checked = !po.checked;
        this.setState({poNumbers, poNotification: null});
    }

    onFacilityChange(e, data) {
        let {selectedPoLine} = this.state;
        let newFacility = data.options.find(x => x.facilityId === data.value);
        selectedPoLine.facilityId = newFacility ? newFacility.facilityId : -1;
        selectedPoLine.facilityName = newFacility ? newFacility.facilityName : '';
        this.setState({selectedPoLine});
    }

    onPoNumberChange(e) {
        let {selectedPoLine} = this.state;
        selectedPoLine.poNumber = e.target.value;
        this.setState({selectedPoLine});
    }

    handlePoSave() {
        let {selectedPoLine, userId} = this.state;

        if ((!selectedPoLine.facilityId || selectedPoLine.facilityId < 0) || !selectedPoLine.poNumber) {
            this.setState({showErrors: true});
            return;
        }

        this.setState({savingPo: true});
        axios.post('/ShoppingService/api/v1/account/blanketPo/save', [selectedPoLine]).then(() => {
            logEvent('Payment Methods Edit', {UserId: userId});
            this.getBlanketPos();
            this.setState({selectedPoLine: null, showPoDialog: false, savingPo: false, showErrors: false});
        })
    }

    onPoNumbersDelete() {
        const {poNumbers, userId} = this.state;
        let poToDelete = JSON.parse(JSON.stringify(poNumbers.filter(x => x.checked)));
        poToDelete.forEach(x => x.active = 'N');
        const poNotification = `(${poToDelete.length}) ${poToDelete.length === 1 ? 'item' : 'items'} deleted`;
        this.setState({deletedPoNumbers: poToDelete, poNotification});
        axios.post('/ShoppingService/api/v1/account/blanketPo/save', poToDelete).then(() => {
            logEvent('Payment Methods Edit', {UserId: userId});
            this.getBlanketPos();
        });
    }

    undoPoDeletes() {
        let {deletedPoNumbers, userId} = this.state;
        deletedPoNumbers.forEach(x => x.active = 'Y');
        axios.post('/ShoppingService/api/v1/account/blanketPo/save', deletedPoNumbers).then(() => {
            logEvent('Payment Methods Edit', {UserId: userId});
            this.setState({poNotification: null, selectedPoLine: null, deletedPoNumbers: []});
            this.getBlanketPos();
        });
    }

    render() {
        const {
            ccNotification,
            showCCDialog,
            creditCards,
            cybersourceRequest,
            isCCEdit,
            showCybersourceError,
            poNotification,
            poNumbers,
            showPoDialog,
            selectedPoLine,
            showErrors,
            savingPo,
            token} = this.state;

        const {user: {facilities, settings, info: {rootCompanyId}, turnstileCompanies}} = this.props;
        const sortedFacilities = facilities ? _.sortBy(facilities, ['facilityName']) : [];
        let checkedItems = creditCards.filter(x => x.checked);
        let allItemsChecked = creditCards.filter(x => !x.checked).length === 0 && creditCards.length > 0;

        let checkedPos = poNumbers.filter(x => x.checked);
        let allPosChecked = poNumbers.filter(x => !x.checked).length === 0 && poNumbers.length > 0;

        return (
            <div className="settings-landing">
                {turnstileCompanies?.includes(rootCompanyId) && <div id="turnstile"></div>}
                <PageMetaData 
                    title="My Account" 
                    pageType="other"
                    trackAnalytics={true} />
                <BreadCrumbs />
                <div className="settings-title">
                    <div className="space-nav"><i className="fa fa-chevron-left" />  <NavLink className="grid-header-link" to="/settings">Back To Settings</NavLink></div>
                </div>
                <div className="payment-settings">
                    <div className="payment-header">Payment Methods</div>
                    {settings.canUseCC && 
                    <React.Fragment>
                        <div className="section-title">Credit Card Settings</div>
                        {ccNotification &&
                            <div className="selection-bar">
                                <span>{ccNotification}</span>
                                <span className="buttons">
                                    <span className="undo" onClick={this.onReActivateDeletedCards}>undo</span>
                                    <i className="fa fa-remove" onClick={() => this.setState({ccNotification: null, deletedCards: []})} />
                                </span>
                            </div>}
                        {checkedItems.length > 0 &&
                            <div className="selection-bar">
                                <span>{`(${checkedItems.length}) ${checkedItems.length === 1 ? 'item' : 'items'} selected`}</span>
                                <span className="buttons">
                                    <i className="fa fa-trash" onClick={::this.onCreditCardDelete} />
                                </span>
                            </div>}
                        {!ccNotification && checkedItems.length === 0 ?
                            <div className="add-item" disabled={!!token} onClick={() => this.generateCybersourceRequest()}>+ ADD CREDIT CARD</div> : null}
                        {creditCards.length > 0 ?
                            <table className="my-account-table">
                                <thead>
                                    <tr>
                                        <th><div className="checkbox-wrapper"><Checkbox checked={allItemsChecked} onChange={::this.onCheckAllChange} /></div></th>
                                        <th>Card Type & Number</th>
                                        <th>Expiration Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {creditCards.map((c) => this.renderCreditCardRow(c))}
                                </tbody>
                            </table> : null}
                    </React.Fragment>}
                    
                    <div className="section-title">Blanket PO Settings</div>
                    <div className="po-message">Adding a blanket PO will make it the default for orders at each facility.</div>
                    {poNotification ? 
                        <div className="selection-bar">
                            <span>{poNotification}</span>
                            <span className="buttons">
                                <span className="undo" onClick={::this.undoPoDeletes}>undo</span>
                                <i className="fa fa-remove" onClick={() => this.setState({poNotification: null, deletedPoNumbers: []})} /></span>
                        </div> : null}
                    {checkedPos.length > 0 ?
                        <div className="selection-bar">
                            <span>{`(${checkedPos.length}) ${checkedPos.length === 1 ? 'item' : 'items'} selected`}</span>
                            <span className="buttons">
                                <i className="fa fa-trash" onClick={::this.onPoNumbersDelete} />
                            </span>
                        </div> : null}
                    {!poNotification && checkedPos.length === 0 ?
                        <div className="add-item" onClick={() => this.setState({showPoDialog: true, selectedPoLine: {}})}>+ ADD BLANKET PO</div> : null}
                    {poNumbers.length === 0 ? null :
                        <table className="my-account-table">
                            <thead>
                                <tr>
                                    <th><div className="checkbox-wrapper"><Checkbox checked={allPosChecked} onChange={::this.onCheckAllPoChange} /></div></th>
                                    <th>Facility</th>
                                    <th>Blanket PO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {poNumbers.map((p) => this.renderPoRow(p))}
                            </tbody>
                        </table>}

                    {showCCDialog ?
                        <SettingsCybersourceDialog
                            cybersourceRequest={cybersourceRequest}
                            isEdit={isCCEdit}
                            onClose={() => this.setState({showCCDialog: false, showCybersourceError: false})}
                            showError={showCybersourceError} /> :
                        null}
                    {showPoDialog ?                 
                        <Popup
                            confirmText="Save"
                            cancelText="Cancel"
                            show={true}
                            onConfirm={::this.handlePoSave}
                            onCancel={() => this.setState({showPoDialog: false, selectedPoLine: {}, showErrors: false})}
                            className=""
                            loading={savingPo}>
                            <div className="">
                                <h1>{(selectedPoLine.id || 0) === 0 ? 'Add Blanket PO' : 'Edit Blanket PO'}</h1>
                                <Dropdown id="facility" name="facility" label="Facility" valueField="facilityId" textField="facilityName" showErrorMessage={showErrors && (!selectedPoLine.facilityId || selectedPoLine.facilityId < 0)}
                                    options={sortedFacilities} selectedValue={selectedPoLine.facilityId} tabIndex={0} onChange={::this.onFacilityChange} />
                                <TextField id="poNumber" label="Blanket PO" showErrorMessage={showErrors && !selectedPoLine.poNumber} tabIndex={0}
                                    placeholder="Blanket PO" text={selectedPoLine.poNumber} onChange={::this.onPoNumberChange} />
                            </div>
                        </Popup> : null}
                </div>
            </div>
        );
    }
}
